<div class="bg-50perc-white p-4 container">
  <h1 class="mat-display-1 text-primary mb-2 text-left">
    Ons is opgewonde om jou hier te he
    <span class="text-secondary">{{ user.Name }}</span>
  </h1>
  <!-- <p *ngIf="!profileFound" class="mat-headline text-left">
    Vul asb jou ID nommer in sodat ons jou profiel kan identifiseer.
  </p>
  <p *ngIf="profileFound" class="mat-headline text-left">
    Bevestig asb dat ons jou profiel kan aktiveer.
  </p>
  <mat-progress-bar
  class="mb-3"
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="0"
  >
  </mat-progress-bar>
  <div class="d-flex justify-content-between">
    <div>
      <button *ngIf="!profileFound && !newProfile" type="button" color="primary" [disabled]="!getMemberByEmail" mat-stroked-button (click)="getMemberByEmail = false">
        Soek ID
      </button>
      <button *ngIf="!profileFound && !newProfile" type="button" color="primary" [disabled]="getMemberByEmail" mat-stroked-button (click)="getMemberByEmail = true">
        Soek Email
      </button>
    </div>
    <button type="button" mat-stroked-button color="primary" (click)="createNewProfile()">
      {{newProfile ? 'Soek Profiel' : 'Nuwe profiel'}}
    </button>
  </div>
  <div class="container-fluid mt-4" *ngIf="!profileFound && !getMemberByEmail && !newProfile">
    <div class="container">
    <form [formGroup]="findProfileForm" class="d-flex flex-column" (ngSubmit)="findProfile()">
      <mat-form-field appearance="outline">
        <mat-label>ID</mat-label>
        <input
          matInput
          placeholder="ID"
          name="ID"
          autofocus
          formControlName="ID"
        />
        <mat-error *ngIf="findProfileForm.controls.ID.invalid"
          >ID word vereis</mat-error
        >
      </mat-form-field>
      <button
      type="submit"
        mat-raised-button
        color="primary"
        class="text-white"
      >
        Vind profiel
      </button>
    </form>
  </div>
  </div>

  <div class="container-fluid mt-4" *ngIf="!profileFound && getMemberByEmail && !newProfile">
    <div class="container">
    <form [formGroup]="findProfileByEmailForm" class="d-flex flex-column" (ngSubmit)="findProfileByEmail()">
      <mat-form-field appearance="outline">
        <mat-label>Epos</mat-label>
        <input autofocus matInput placeholder="Epos" formControlName="Email" />
        <mat-error *ngIf="profileForm.controls.Email.invalid"
          >Epos word vereis</mat-error
        >
      </mat-form-field>
      <button
      type="submit"
        mat-raised-button
        color="primary"
        class="text-white"
      >
        Vind profiel
      </button>
    </form>
  </div>
  </div> -->

  <div class="container-fluid mt-4" >
    <mat-stepper #stepper [orientation]="(stepperOrientation | async)!">
      <mat-step [stepControl]="profileForm" [label]="'Meer oor jou'">
    <div class="row">
      <div class="col-md-12">
        <div class="p-3">
          <h2 class="m-0 text-left">Is die volgende inligting korrek?</h2>
          <p class="m-0 text-left">Maak asb veranderinge indien nodig.</p>
          <form
            class="user"
            [formGroup]="profileForm"
            class="d-flex flex-column mt-4"
          >
            <div class="row">
              <div class="col-md-4 d-flex flex-column">
                <mat-form-field>
                  <mat-label>Titel</mat-label>
                  <mat-select name="title" formControlName="Title">
                    <mat-option
                      *ngFor="let title of titleList"
                      [value]="title"
                      >{{ title }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Naam</mat-label>
                  <input
                    matInput
                    placeholder="Naam"
                    name="name"
                    formControlName="Name"
                  />
                  <mat-error *ngIf="profileForm.controls.Name.invalid"
                    >Name is Required</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Van</mat-label>
                  <input matInput placeholder="Van" formControlName="Surname" />
                  <mat-error *ngIf="profileForm.controls.Surname.invalid"
                    >Surname is Required</mat-error
                  >
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>ID</mat-label>
                  <input
                    matInput
                    placeholder="ID"
                    name="ID"
                    formControlName="ID"
                  />
                  <mat-error *ngIf="profileForm.controls.ID.invalid"
                    >ID is Required</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Geboorte Datum</mat-label>
                  <input
                    readonly
                    matInput
                    [matDatepicker]="DOBPicker"
                    formControlName="DOB"
                  />
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="DOBPicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #DOBPicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-4 d-flex flex-column">
                <mat-form-field>
                  <mat-label>Lidmaatstatus</mat-label>
                  <mat-select
                    name="MemberStatus"
                    formControlName="MembershipStatus"
                  >
                    <mat-option
                      *ngFor="let status of memberStatusList"
                      [value]="status"
                    >
                      {{ status }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Vorige kerk</mat-label>
                  <input
                    matInput
                    placeholder="Vorige kerk"
                    formControlName="PreviousChurch"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Kontak nommer</mat-label>
                  <input
                    matInput
                    placeholder="Kontak nommer"
                    formControlName="Mobile"
                  />
                  <mat-error *ngIf="profileForm.controls.Mobile.invalid"
                    >Mobile is Required</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Epos</mat-label>
                  <input matInput placeholder="Epos" formControlName="Email" />
                  <mat-error *ngIf="profileForm.controls.Email.invalid"
                    >Email is Required</mat-error
                  >
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Woonadres</mat-label>
                  <input
                    matInput
                    placeholder="Woonadres"
                    formControlName="Address"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-4 d-flex flex-column">
                <mat-form-field>
                  <mat-label>Huweliks status</mat-label>
                  <mat-select
                    name="MaritalStatus"
                    formControlName="MaritalStatus"
                  >
                    <mat-option
                      *ngFor="let status of maritalStatusList"
                      [value]="status"
                    >
                      {{ status }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container
                  *ngIf="
                    profileForm.controls['MaritalStatus'].value === 'Getroud'
                  "
                >
                  <mat-form-field appearance="outline">
                    <mat-label>Troudatum</mat-label>
                    <input
                      readonly
                      matInput
                      [matDatepicker]="MariageDatePicker"
                      formControlName="MariageDate"
                    />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="MariageDatePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #MariageDatePicker></mat-datepicker>
                  </mat-form-field>
                  </ng-container>
                  <div class="d-flex">
                  <button *ngIf="profileForm.controls['MaritalStatus'].value === 'Getroud'"
                    color="primary"
                    mat-stroked-button
                    (click)="addSpouse()"
                  >
                    <div
                      class="p-3 text-primary d-flex flex-column justify-content-center"
                    >
                      <fa-icon size="2x" [icon]="faUserPlus"></fa-icon>
                      Voeg eggenoot by
                    </div>
                  </button>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="addChild()"
                  aria-label="Voeg kind by"
                >
                  <div
                    class="p-3 text-primary d-flex flex-column justify-content-center"
                  >
                    <fa-icon size="2x" [icon]="faChild"></fa-icon>
                    Voeg kind by
                  </div>
                </button>
            </div>
                <ng-container *ngIf="students">
                    <p>Kinders: {{ students.length }}</p>
                    <mat-chip-list>
                        <mat-chip (click)="openChild(student)" *ngFor="let student of students" style="font-size: 12px">
                            <fa-icon [icon]="faChild"></fa-icon> {{ student.Name }} {{ student.Surname }}</mat-chip>
                      </mat-chip-list>
                  </ng-container>
              </div>
            </div>

            <div class="w-100 d-flex justify-content-end mt-4">
              <div class="d-flex"></div>
              <button type="button" [disabled]="profileForm.invalid" mat-raised-button color="primary" class="text-white" (click)="submit()">
                Bevestig profiel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </mat-step>

      <mat-step [label]="'Word deel'">
        <p>
          By ons gemeente is daar ook vir jou geleentheid om jou gawes en
          talente binne ons bedieninge uit te leef. Dui aan X watter bediening
          jou hart raak en waar jy graag sal betrokke wil raak.....dié
          bedieningspan sal met jou kontak maak!
        </p>
        <div class="row">
          <div class="row m-0 scrollable w-100">
            <div class="col-md-3" *ngFor="let group of groups">
                <!-- <mat-card class="m-3">
                    <mat-card-content> -->
                        <!-- <div class="row"> -->
                            <!-- <div class="col-md-6"> -->
                                <img class="img-fluid" [src]="group.logo?.source">
                                <mat-checkbox (change)="setInterest(group, $event)">{{group.name}}</mat-checkbox>
                                <ng-container *ngIf="group.subGroups">
                                  <mat-checkbox *ngFor="let sub of group.subGroups">{{sub.title}}</mat-checkbox>
                                </ng-container>
                            <!-- </div> -->
                            <!-- <div class="col-md-6">
                                <h1>{{group.title}}</h1>
                                <p>{{group.description}}</p>
                                <button mat-raised-button color="accent">Meer</button>
                            </div> -->
                        <!-- </div> -->
                    <!-- </mat-card-content>
                </mat-card> -->
            </div>
        </div>
        </div>
        <div class="row d-flex justify-content-between">
          <button mat-raised-button matStepperPrevious>Terug</button>
          <button
          type="button"
            mat-raised-button
            color="primary"
            class="text-white"
            (click)="completeOnboarding()"
          >
            Gaan deur
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
