import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
// import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subject, observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Service } from 'backend/interfaces/service.interface';
import * as moment from 'moment';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { faCalendar, faCalendarAlt, faChild, faMapMarker, faMapMarkerAlt, faNotesMedical, faPlus, faUserAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ParentDataService } from 'src/app/parent/services/data.service';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Student } from 'backend/interfaces/student.interface';
import { MinistryService } from 'src/app/admin/services/ministry.service';
import { Ministry } from 'backend/interfaces/ministry.interface';
import { MatCheckboxChange, MatCheckboxClickAction } from '@angular/material/checkbox';

export interface TimeSlot {
  time: string,
  selected: boolean
}
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  // @ViewChild('stepper') stepper: MatStepper;
  @Input('user') user;
  @Output('complete') complete: EventEmitter<any> = new EventEmitter(null);
  @Output('createStudent') createStudent: EventEmitter<any> = new EventEmitter(null);
  @Output('openStudent') openStudent: EventEmitter<any> = new EventEmitter(null);
  @Output('createUser') createUser: EventEmitter<any> = new EventEmitter(null);
  groups;

  titleList = [
    'Mnr', 'Me'
  ];
  maritalStatusList = [
    'Getroud', 'Ongetroud', 'Geskei', 'Wewenaar', 'weduwee',
  ];
  memberStatusList = [
    'Nuwe Intrekker', 'Belydend', 'Doop', 'Ongedoop',
  ]

  faUserPlus = faUserPlus;
  faChild = faChild;
  faNote = faNotesMedical;
  faCalendar = faCalendar;
  faPlus = faPlus;
  faCalendarAlt = faCalendarAlt;
  faMapMarker = faMapMarker;
  faMapMarkerAlt = faMapMarkerAlt;
  faUser = faUserAlt;
  loading = false;
  loadingPractitioners = false;
  @ViewChild('stepper') stepper: MatStepper;
  services: Service[];
  locations: Location[];
  selectedLocation: string;
  selectedDate: Date;
  startAt = new Date('2023/09/11');
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  busyTimes: Date[];
  timeSlots: TimeSlot[] = [];
  year: any;
  DayAndDate: string;
  practitioners: Practitioner[] = [];
  practitioners$: BehaviorSubject<Practitioner[]> = new BehaviorSubject([]);
  selectedPractitioner = 'Any Available';
  hasSpouse = false;
  hasKids = false;
  appointmentTypeForm = this._formBuilder.group({
    type: ['', Validators.required],
    duration: ['']
  });
  userSystemId;
  profileFound = false;
  newProfile = false;
  getMemberByEmail = false;
  involvedGroups = [];
  appointmentLocationForm = this._formBuilder.group({
    location: ['', Validators.required]
  });
  appointmentDateForm = this._formBuilder.group({
    date: ['', Validators.required]
  });
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  findProfileForm = this._formBuilder.group({
    ID: ['', Validators.required]
  });

  findProfileByEmailForm = this._formBuilder.group({
    Email: ['', Validators.required]
  });

  profileForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    AdditionalNotes: [''],
    Title: [''],
    DOB: ['', Validators.required],
    ID: [''],
    Address: [''],
    PostalAddress: [''],
    MembershipStatus: [''],
    MaritalStatus: [''],
    MariageDate: [''],
    PreviousChurch: [''],
    Occupation: [''],
    Employer: ['']
  });
  spouseForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    AdditionalNotes: [''],
    Title: [''],
    Birthday: [],
    ID: [''],
    Address: [''],
    PostalAddress: [''],
    MembershipStatus: [''],
    MaritalStatus: [''],
    MariageDate: [''],
    PreviousChurch: [''],
    Occupation: [''],
    Employer: ['']
  });

  // stepperOrientation: Observable<StepperOrientation>;
  students;
  profile;
  stepperOrientation: Observable<StepperOrientation>;
  constructor(private router: Router, private _formBuilder: UntypedFormBuilder, private authService: AuthService, private dataService: ParentDataService, breakpointObserver: BreakpointObserver, private _snackBar: MatSnackBar, private ministryService: MinistryService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({ matches }) => matches ? 'horizontal' : 'vertical'));
  }

  async ngOnInit(): Promise<void> {
    console.log(this.user)
    this.ministryService.getMinistries();
    this.authService.$User.subscribe(res => this.userSystemId = res?.id)
    if (!this.user) {
      this.authService.$User.subscribe(res => {
        if (res) {
          this.user = {
            Name: res.get('Name'),
            Surname: res.get('Surname'),
            Mobile: res.get('Mobile'),
            Email: res.get('username'),
            ID: res.get('ID'),
            role: res.get('role')
          }
          this.dataService.parent.next(res);
          this.initForm();
          // this.tempuser = {Name: this.user?.Name, Surname: this.user?.Surname, Email: this.user?.Email, Mobile: this.user?.Mobile}
          this.profileForm.controls['MembershipStatus'].setValue('Nuwe Intrekker')
        }
      });
    }else{
      this.initForm();
    }
    this.ministryService.ministries$.subscribe(res => {
      if(res)
        this.groups = res
    });
  }

  submit(): void {
    this.loading = true;
    this.stepper.next();
    this.profileForm.value.DOB = new Date(this.profileForm.value.DOB).toLocaleDateString();
    this.dataService.saveParent(this.profileForm.value).then(res => {
      this.profile = res;
      // this.dataService.parent.next(res);
      this.authService.$User.subscribe(res => res.id)
      this.dataService.updateUserRole(this.userSystemId, 'member').then(res => {
        this.loading = false;
        this.openSnackBar('Profiel opgedateer.')
      }).catch(err => this.openSnackBar(err))
    }).catch(err => console.log(err))
  }

  initForm(): void {
    console.log(this.user)
    this.profileForm.patchValue({
      Title: this.user.Title,
      Name: this.user.Name,
      Surname: this.user.Surname,
      MaritalStatus: this.user.MaritalStatus,
      MariageDate: this.user.MariageDate,
      Mobile: this.user.Mobile,
      Email: this.user.Email,
      ID: this.user.ID,
      DOB: this.user.DOB ? new Date(this.user.DOB) : this.getDateOfBirth(this.user.ID ? this.user.ID : '')
    })

    this.dataService.students$.subscribe(res => {
      this.students = res;
    })
  }

  goHome() {
    this.router.navigateByUrl('');
  }

  addSpouse() {
    this.createUser.emit();
  }

  addChild() {
    this.createStudent.emit();
  }

  openChild(student: Student) {
    this.openStudent.emit(student);
  }

  getDateOfBirth(idNumber): Date {
    let birthday;
    const currentYear = new Date().getFullYear();
    const year = idNumber?.substring(0, 2);
    const month = idNumber?.substring(2, 4);
    const day = idNumber?.substring(4, 6);
    var date = new Date(month + "/" + day + "/" + year);
    if (date.getFullYear() > currentYear) {
      birthday = new Date(new Date(month + "/" + day + "/" + '19' + year))
    } else {
      birthday = new Date(new Date(month + "/" + day + "/" + year))
    }
    return birthday
  }

  completeOnboarding() {
    this.dataService.saveParent({...this.profile, InvolvedIn: this.involvedGroups}).then(res => this.dataService.parent.next(res));
    this.complete.emit();
    this.router.navigate(['member', 'dashboard'])
  }

  async findProfile(): Promise<void> {
    this.loading = true;
    console.log(this.findProfileForm.value)
    await this.dataService.getParentProfile(this.findProfileForm.value.ID).then(result => {
      if (result) {
        console.log(result)
        this.dataService.parent.next(result);
        this.user = result;
        this.profileFound = true;
        this.loading = false;
        this.openSnackBar('Sukses!');
        this.initForm();
      } else {
        this.openSnackBar('Geen profiel gevind.');
        this.loading = false
      }
    }).catch(err => this.openSnackBar(err));
  }


  async findProfileByEmail(): Promise<void> {
    this.loading = true;
    console.log(this.findProfileByEmailForm.value)
    await this.dataService.getMemberByEmail(this.findProfileByEmailForm.value.Email).then(result => {
      if (result) {
        console.log(result)
        this.dataService.parent.next(result);
        this.user = result;
        this.profileFound = true;
        this.loading = false;
        this.openSnackBar('Sukses!');
        this.initForm();
      } else {
        this.openSnackBar('Geen profiel gevind.');
        this.loading = false
      }
    }).catch(err => this.openSnackBar(err));
  }
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000,
      verticalPosition: 'top'
    });
  }

  createNewProfile(): void {
    this.newProfile = !this.newProfile;
  }

  setInterest(group: Ministry, event: MatCheckboxChange){
    if(event.checked){
      this.involvedGroups.push(group.name);
    }else{
      this.involvedGroups = this.involvedGroups.filter(g => g !== group.name)
    }
    
  }
}