import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subject, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { Service } from 'backend/interfaces/service.interface';
import * as moment from 'moment';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { faCalendar, faCalendarAlt, faMapMarker, faMapMarkerAlt, faNotesMedical, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { AppointmentService } from '../services/appointment.service';
import { Router } from '@angular/router';

export interface TimeSlot {
  time: string,
  selected: boolean
}
@Component({
  selector: 'app-new-appointment',
  templateUrl: './new-appointment.component.html',
  styleUrls: ['./new-appointment.component.scss']
})
export class NewAppointmentComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  faNote = faNotesMedical;
  faCalendar = faCalendar;
  faCalendarAlt = faCalendarAlt;
  faMapMarker = faMapMarker;
  faMapMarkerAlt = faMapMarkerAlt;
  faUser = faUserAlt;
  loading = false;
  loadingPractitioners = false;
  services: Service[];
  locations: Location[];
  selectedLocation: string;
  selectedDate: Date;
  startAt = new Date('2023/09/11');
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  busyTimes: Date[];
  timeSlots: TimeSlot[] = [];
  year: any;
  DayAndDate: string;
  practitioners: Practitioner[] = [];
  practitioners$: BehaviorSubject<Practitioner[]> = new BehaviorSubject([]);
  selectedPractitioner = 'Any Available';
  appointmentTypeForm = this._formBuilder.group({
    type: ['', Validators.required],
    duration: ['']
  });
  appointmentLocationForm = this._formBuilder.group({
    location: ['', Validators.required]
  });
  appointmentDateForm = this._formBuilder.group({
    date: ['', Validators.required]
  });
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }
  profileForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    BookingForSomeoneElse: [''],
    AdditionalNotes: ['']
  });
  bookingAgentForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    RelationToStudent: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>; 

  constructor(private router: Router, private _formBuilder: UntypedFormBuilder, breakpointObserver: BreakpointObserver, private dataService: DataService, private appointmentService: AppointmentService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({ matches }) => matches ? 'horizontal' : 'vertical'));
  }

  ngOnInit(): void {
    this.timeSlots = [];
    this.dataService.getLocations();
    this.dataService.getServices(100, 0);
    this.dataService.getPractitioners();
    this.dataService.services$.subscribe(res => {
      this.services = res;
    });
    this.dataService.locations$.subscribe(res => {
      this.locations = res;
    });
    this.dataService.practitioners$.subscribe(res => {
      this.practitioners = res;
    });
  }

  setAppointmentType(title: string, duration: string) {
    this.appointmentTypeForm.controls['type'].setValue(title);
    this.appointmentTypeForm.controls['duration'].setValue(duration);
    this.stepper.next();
  }

  async setLocation(location: string): Promise<void> {
    this.loadingPractitioners = true;
    this.selectedLocation = location;
    this.appointmentLocationForm.controls['location'].setValue(location);
    this.selectedPractitioner = 'Any Available';
    const practitionersAtLocation = this.practitioners.filter((obj) => {
      if(obj.locations){
        if(obj.locations.some(location => location.name === this.selectedLocation)){
          return obj;
        }
      }
    });
    this.practitioners$.next(practitionersAtLocation);
    this.loadingPractitioners = false;
  }

  setPractitioner(id: string): void {
    console.log(id)
    this.selectedPractitioner = id;
    // this.stepper.next();
  }

  setDate(date: string) {
    this.appointmentDateForm.controls['date'].setValue(date);
    this.stepper.next();
  }

  selectDate(event: any) {
    this.timeSlots = [];
    this.selectedDate = event;
    const dateString = event.toDateString();
    const dateValue = dateString.split(' ');
    this.year = dateValue[3];
    this.DayAndDate = dateValue[0] + ',' + ' ' + dateValue[1] + ' ' + dateValue[2];
    this.getAvailableTimes(this.selectedDate);
  }

  myDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    const now = new Date();
    now.setHours(0,0,0,0);
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 7;
  }

  getAvailableTimes(_event) {
    const date = new Date(_event);
    const duration = parseInt(this.appointmentTypeForm.controls['duration'].value.split(' ')[0]);
    this.dataService.getAvailableTimes(duration, date.getDate(), date.getMonth(), date.getFullYear(), this.selectedLocation, this.selectedPractitioner).then(res => {
      res.forEach(slot => {
        this.timeSlots.push({time: slot, selected: false})
      });
    })
  }

  selectTimeSlot(timeSlot: TimeSlot): void {
    const time = moment(timeSlot.time);
    this.selectedDate = new Date(timeSlot.time);
    this.appointmentDateForm.controls['date'].setValue(timeSlot.time);
    this.stepper.next();
  }

  makeAppointment(): void {
    const appointment = {
      appointmentType: this.appointmentTypeForm.controls['type'].value,
      appointmentLocation: this.appointmentLocationForm.controls['location'].value,
      appointmentDate: this.appointmentDateForm.controls['date'].value,
      appointmentDuration: this.appointmentTypeForm.controls['duration'].value,
      studentName: this.profileForm.controls['Name'].value,
      studentSurname: this.profileForm.controls['Surname'].value,
      studentMobile: this.profileForm.controls['Mobile'].value,
      studentEmail: this.profileForm.controls['Email'].value,
      studentHasAgent: this.profileForm.controls['BookingForSomeoneElse'].value.toString(),
      additionalNotes: this.profileForm.controls['AdditionalNotes'].value.toString(),
      preferredPractitioner: this.selectedPractitioner,
      bookingAgentName: this.bookingAgentForm.controls['Name'].value,
      bookingAgentSurname: this.bookingAgentForm.controls['Surname'].value,
      bookingAgentMobile: this.bookingAgentForm.controls['Mobile'].value,
      bookingAgentEmail: this.bookingAgentForm.controls['Email'].value,
      bookingAgentRelationToStudent: this.bookingAgentForm.controls['RelationToStudent'].value,
      colorId: this.locations.find(l => l.name === this.selectedLocation).colorId
    }
    this.appointmentService.createAppointmentRequest(appointment).then(res => {
      this.stepper.next();
    }).catch(err => console.log(err));
  }

  getAppointmentsByLocation(): void{
    this.appointmentService.getAppointmentsByLocation('Centurion');
  }

  continueToSignIn(){
    this.router.navigateByUrl('auth');
  }

  goHome(){
    this.router.navigateByUrl('');
  }

}