import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Parse from 'parse';

import { Student } from 'backend/interfaces/student.interface';
import { Member } from 'backend/interfaces/member.interface';

@Injectable({
  providedIn: 'root'
})
export class ParentDataService {
  parent = new BehaviorSubject<Member | null>(null);
  parent$ = this.parent.asObservable();
  students = new BehaviorSubject<Student[] | null>(null);
  students$ = this.students.asObservable();
  memberConsent = new BehaviorSubject<any[] | null>(null);
  memberConsent$ = this.memberConsent.asObservable();
  constructor() {
  }

  getParentProfile(id: string): Promise<Member> {
    return Parse.Cloud.run('getMemberById', { id })
  }

  getMemberByEmail(email: string): Promise<Member> {
    return Parse.Cloud.run('getMemberByEmail', { email })
  }

  getStudentsByParentId(id: string, mainContact: boolean): Promise<Student[]> {
    return Parse.Cloud.run('getStudentsByParentId', { id, mainContact })
  }

  saveParent(parent: Member): Promise<Member> {
    return Parse.Cloud.run('saveParent', { parent }).then((result) => {
      return result;
    });
    
  }
  updateUserRole(userSystemId: string, role: string): Promise<any> {
    return Parse.Cloud.run('updateUserRole', {userSystemId, role }).then((result) => {
      return result;
    });
    
  }

  getMemberConsent(): Promise<any[]> {
    return Parse.Cloud.run('getMemberConsent', {}).then((res) => {
      this.memberConsent.next(res.get('memberConsent'));
    });
  }
}
