

<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

  <div *ngIf="profile.PhotoAlbumId">
    <input
    type="file"
    class="file-input"
    (change)="onPhotoSelected($event)"
    #fileInput

  /><button mat-stroked-button color="primary" (click)="uploadPhoto()">upload</button>
</div>
<button mat-stroked-button color="accent" (click)="createPhotoAlbum()" *ngIf="!loading && !profile?.PhotoAlbumId">Create Photo Album</button>
<lightgallery *ngIf="!loading && files" [settings]="settings" [onBeforeSlide]="onBeforeSlide" class="row justify-content-center scrollable p-2 flex-1">
  <p *ngIf="files.length < 1">No photos</p>

  <a *ngFor="let file of files"
    class="col-md-3 d-flex flex-column align-items-center justify-content-center photo-container"
    [href]="file.baseUrl">
    <img class="img-responsive"
    src="{{file.baseUrl}}" />
  </a>

</lightgallery>