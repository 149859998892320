import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MinistryService } from 'src/app/admin/services/ministry.service';

import { SideNavService } from 'src/app/admin/services/side-nav.service';

@Component({
  selector: 'app-search-ministry',
  templateUrl: './search-ministry.component.html',
  styleUrls: ['./search-ministry.component.scss']
})
export class SearchMinistryComponent implements OnInit {

  loading = false;
  searchMinistryForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private ministryService: MinistryService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchMinistryForm = this.fb.group(
        {
          name: ['']
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.ministryService.searchMinistry(this.searchMinistryForm.value).then(res => {
      if(res.length > 0){
        this.ministryService.ministries.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
