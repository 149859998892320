import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { CalendarEvent } from 'angular-calendar';

export enum SidenavActions {
  NewAppointment = 'Skep Afspraak',
  NewParent = 'Skep Kruiner',
  NewStudent = 'Skep leerder',
  ViewStudent = 'Kruin leerder',
  SearchParent = 'Soek ouer',
  SearchStudent = 'Soek leerder',
  UpdateAppointment = 'Afspraak',
  ReviewAppointment = 'Afspraak versoek',
  NewLocation = 'Skep lokaal',
  SearchLocation = 'Soek Location',
  NewMealPlan = 'Skep eetplan',
  SearchMealPlan = 'Soek eetplan',
  NewMeal = 'Skep ete',
  SearchMeal = 'Soek ete',
  UpdateService = 'Gebeurtenis',
  NewService = 'Skep Gebeurtenis',
  SearchService = 'Soek Gebeurtenis',
  NewPractitioner = 'Skep personeel',
  SearchPractitioner = 'Soek personeel',
  NewGroup = 'Skep Groep',
  UpdateGroup = 'Groep',
  SearchGroup = 'Soek klas',
  NewMinistry = 'Skep Bediening',
  UpdateMinistry = 'Bediening',
  SearchMinistry = 'Soek bediening',
}

@Injectable({
  providedIn: 'root'
})


export class SideNavService {
  sidenav: MatSidenav;
  sidenavAction: SidenavActions;
  loading = false;
  newParentEmail: string;
  public newBookingForm: CalendarEvent;
  constructor() { }

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  open(action: SidenavActions, newParentEmail?: string) {
      this.sidenavAction = action;
      this.newParentEmail = newParentEmail; 
      console.log(this.sidenavAction)
      return this.sidenav.open();
  }


  close() {
      this.loading = false;
      return this.sidenav.close();
  }

  toggle(): void {
    this.loading = false;
    this.sidenav.toggle();
  }

  
}
