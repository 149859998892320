import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faFile, faFilePdf, faImage, faUser } from '@fortawesome/free-solid-svg-icons';
import { Student } from 'backend/interfaces/student.interface';
import { StudentService } from 'src/app/admin/services/student.service';
import { FileViewerDialogComponent } from '../file-viewer-dialog/file-viewer-dialog.component';
import { ParentService } from 'src/app/admin/services/parent.service';

import { Router } from '@angular/router';
import { Member } from 'backend/interfaces/member.interface';
@Component({
  selector: 'app-gdrive',
  templateUrl: './gdrive.component.html',
  styleUrls: ['./gdrive.component.scss']
})
export class GdriveComponent implements OnInit {

  constructor(private parentService: ParentService, private _snackBar: MatSnackBar, readonly dialog: MatDialog, private router: Router) { }

  loading = false;
  parent: Member;
  files;
  selectedFile: {type: string, src: string};
  uploadedFile = {name: '', type: '', source: null};
  faImage = faImage;
  faFile = faFile;
  faUser = faUser
  async ngOnInit() {
    this.loading = true;
    this.parentService.activatedParent$.subscribe(async s => {
      return this.parent = s;
    });
    this.parentService.activatedDrive$.subscribe(async d => {
      this.files = d;
    })
    this.loading = false
  }

  createDriveFolder(){
    this.loading = true;
    this.parentService.createDriveFolder(this.parent.ID).then(async result => {
      // this.parent.DriveId = result.DriveId;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['admin', 'member', this.parent.ID, 'files']));
      this.loading = false;
    });
  }

  async uploadFile(){
    let description = '';
    this.loading = true;
    this.parentService.uploadFile(this.parent.DriveId, this.uploadedFile, description).then(async res => {
      this.openSnackBar('Upload Successful!');
      await this.parentService.getparentFiles(this.parent.DriveId).then(res => {
        if(res.includes("not found"))
        {
          console.log(res)
        }else{
          this.files = res;
        }
      })
      this.loading = false;
    });
  }


async getFile(fileId: string, mimeType: string, fileName: string){
  this.loading = true;
  // document.getElementById('showcaseImage').setAttribute("src", '');
  // document.getElementById('showcaseFile').setAttribute("src", '');
  return this.parentService.getParentFile(fileId, mimeType).then(res => {
    // if(mimeType.includes('image')){
    //   document.getElementById('showcaseImage').setAttribute("src", res);
    // }else{
      this.viewDriveFolder(fileName, mimeType, res, fileId)
      // document.getElementById('showcaseFile').setAttribute("src", res);
    // }
    this.selectedFile = {
      type: mimeType.includes('image') ? 'image' : 'file',
      src: res
    }
    this.loading = false;
    return res;
  }).catch(err => console.log(err))

}
  
  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
          reader.onload = () => {
              this.uploadedFile = {
                source: reader.result,
                type: file.type,
                name: file.name
              }
          };
          reader.readAsDataURL(file)
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
  async viewDriveFolder(name, mimeType, file, fileId){
    // if(mimeType.includes('image')){
    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
      data: {name, mimeType, file, fileId},
      width: '90%',
      maxWidth: '100vw',
      height: '90%',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
      } else {
        this.dialog.closeAll();
      }
    });
  }
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    
      //decode base64 data
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
    
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
    
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
    
          var byteArray = new Uint8Array(byteNumbers);
    
          byteArrays.push(byteArray);
      }
    
      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }
  
}
