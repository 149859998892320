
    <div *ngFor="let group of groups" (click)="openDialog(group)">
        <!-- <mat-card class="m-3">
            <mat-card-content> -->
                <!-- <div class="row"> -->
                    <!-- <div class="col-md-6"> -->
                        <img class="img-fluid icon" [src]="group.icon">
                    <!-- </div> -->
                    <!-- <div class="col-md-6">
                        <h1>{{group.title}}</h1>
                        <p>{{group.description}}</p>
                        <button mat-raised-button color="accent">Meer</button>
                    </div> -->
                <!-- </div> -->
            <!-- </mat-card-content>
        </mat-card> -->
    </div>
