
    <mat-progress-bar
        [mode]="loading ? 'indeterminate' : 'determinate'"
        [value]="0">
    </mat-progress-bar>

<form *ngIf="newAppointmentForm && appointmentAction === sidenavActions.NewAppointment" [formGroup]="newAppointmentForm" (submit)="makeAppointment()">
    <div class="d-flex p-3 flex-column relative">
    <mat-form-field>
        <mat-label>Tipe</mat-label>
        <mat-select (selectionChange)="setAppointmentType($event)" formControlName="appointmentType" name="appointmentType">
          <mat-option>Geen</mat-option>
          <mat-option *ngFor="let service of services" [value]="service.title">
            <div class="d-flex flex-column">
                {{service.title}} 
                <span *ngIf="service.duration">{{service.duration}}</span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field>
        <mat-label>Lokaal</mat-label>
        <mat-select formControlName="appointmentLocation" name="appointmentLocation" (selectionChange)="setLocation($event)">
          <mat-option>Geen</mat-option>
          <mat-option *ngFor="let location of locations" [value]="location.name">
            {{location.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Personeel</mat-label>
        <mat-select formControlName="preferredPractitioner" name="preferredPractitioner" (selectionChange)="setPractitioner($event)">
          <mat-option value="Any Available">Any Available</mat-option>
          <mat-option *ngFor="let practitioner of practitioners" [value]="practitioner.id">
            {{practitioner.name}} {{practitioner.surname}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Datum</mat-label>
        <input readonly [minDate]="minDate" [matDatepickerFilter]="myDateFilter" matInput (dateChange)="selectDate($event)" [matDatepicker]="picker1" formControlName="appointmentDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
      <!-- <mat-calendar
      [selected]="selectedDate" 
      [dateFilter]="myDateFilter"
      [minDate]="minDate"
      (selectedChange)="selectDate($event)"></mat-calendar> -->
      <ng-container *ngIf="selectedDate && timeSlots.length > 0">
          <!-- <mat-chip-list *ngIf="timeSlots.length > 0" class="mat-chip-list-stacked m-3">
          <mat-chip
              #chipRef="matChip"
              *ngFor="let slot of timeSlots"
              class="link justify-content-center"
              (click)="chipRef.selectViaInteraction(); selectTimeSlot(slot);"
              [selected]="slot.selected"
          >
          {{slot.time | date: 'HH:mm':'UTC +2'}}
          </mat-chip>
          </mat-chip-list> -->
          <p class="text-primary mt-3">Beskikbare tye</p>
          <mat-form-field>
            <mat-label>Tyd</mat-label>
          <mat-select name="appointmentStart" formControlName="appointmentStart" (selectionChange)="setTimeFromDropdown($event)">
            <mat-option value="">Select a time slot</mat-option>
            <mat-option *ngFor="let slot of timeSlots" [value]="slot.time">
              {{slot.time | date: 'HH:mm'}}
            </mat-option>
          </mat-select>
          </mat-form-field>
      </ng-container>
      <p class="text-primary">Kontak persoon</p>
      <mat-form-field appearance="outline">
        <mat-label>Naam</mat-label>
        <input matInput placeholder="Name" name="name" formControlName="studentName">
        <mat-error *ngIf="newAppointmentForm.controls.studentName.invalid">Name is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Van</mat-label>
        <input matInput placeholder="Surname" formControlName="studentSurname">
        <mat-error *ngIf="newAppointmentForm.controls.studentSurname.invalid">Surname is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Selfoon Nommer</mat-label>
        <input matInput placeholder="Mobile" formControlName="studentMobile">
        <mat-error *ngIf="newAppointmentForm.controls.studentMobile.invalid">Mobile is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Epos</mat-label>
        <input matInput placeholder="Email" formControlName="studentEmail">
        <mat-error *ngIf="newAppointmentForm.controls.studentEmail.invalid">Email is Required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Notas</mat-label>
      <input matInput placeholder="Notes" formControlName="additionalNotes">
  </mat-form-field>
    </div>
    <div mat-toolbar class="d-flex justify-content-between p-3">
        <button *ngIf="appointmentAction !== sidenavActions.ReviewAppointment" mat-raised-button color="accent" [disabled]="newAppointmentForm.invalid" type="submit" class="btn btn-outline-secondary">
            {{ loading ? '...' : 'Stoor' }}
        </button>
    </div>
</form>
<form *ngIf="appointmentForm && appointmentAction !== sidenavActions.NewAppointment" [formGroup]="appointmentForm" (submit)="updateAppointment()">

    <div class="d-flex p-3 flex-column">
        <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select (selectionChange)="setAppointmentType($event)" formControlName="summary" name="summary">
              <mat-option *ngFor="let service of services" [value]="service.title">
                <div class="d-flex flex-column">
                    {{service.title}} 
                    <span *ngIf="service.duration">{{service.duration}}</span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        <mat-form-field>
            <mat-label>Lokaal</mat-label>
            <mat-select formControlName="location" name="location">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let location of locations" [value]="location.name">
                {{location.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field color="primary" appearance="outline">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="dp1" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1></mat-datepicker>
          </mat-form-field> -->
          <mat-form-field color="accent">
            <mat-label>Datum</mat-label>
            <input readonly [minDate]="minDate" [matDatepickerFilter]="myDateFilter" matInput (dateChange)="selectDate($event)" [matDatepicker]="picker1" formControlName="date">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Tyd</mat-label>
                <input matInput placeholder="Start time" name="start" formControlName="start">
                <mat-error>Time is Required</mat-error>
            </mat-form-field>
            <p class="text-primary mt-3">Beskikbare tye</p>
            <mat-form-field>
                <mat-label>Beskikbare tye</mat-label>
              <mat-select name="appointmentStart" formControlName="date" (selectionChange)="setTimeFromDropdown($event)">
                <mat-option value="">Kies 'n tyd</mat-option>
                <mat-option *ngFor="let slot of timeSlots" [value]="slot.time">
                  {{slot.time | date: 'HH:mm'}}
                </mat-option>
              </mat-select>
              </mat-form-field>
              <p class="text-primary">Kontak persoon</p>
              <mat-form-field appearance="outline">
                <mat-label>Naam</mat-label>
                <input matInput placeholder="Name" name="name" formControlName="studentName">
                <mat-error *ngIf="appointmentForm.controls.studentName.invalid">Name is Required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Van</mat-label>
              <input matInput placeholder="Surname" formControlName="studentSurname">
              <mat-error *ngIf="appointmentForm.controls.studentSurname.invalid">Surname is Required</mat-error>
          </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Epos</mat-label>
            <input matInput placeholder="Student Email" name="attendees" formControlName="attendeeEmail">
            <mat-error>Email is Required</mat-error>
        </mat-form-field>
        <div class="d-flex">
            <mat-form-field appearance="outline" class="flex-1">
                <mat-label>Notas</mat-label>
                <textarea matInput placeholder="Notes" name="attendees" formControlName="notes"></textarea>
            </mat-form-field>
        </div>
        <div class="d-flex flex-wrap flex-column">
          <p>Betrokke personeel:<br>{{selectedPractitioner}}</p>
        </div>
    </div>

    <div mat-toolbar class="d-flex justify-content-between p-3">
        <button *ngIf="appointment?.meta?.id && appointmentAction !== sidenavActions.ReviewAppointment" mat-raised-button color="default" [disabled]="loading || recordingNotes" type="button" class="btn btn-outline" (click)="openDeleteDialog()">
            <fa-icon [icon]="faTrash"></fa-icon> 
        </button>
        <button *ngIf="appointmentAction !== sidenavActions.ReviewAppointment && appointment.id" mat-raised-button color="accent" [disabled]="appointmentForm.invalid || loading || recordingNotes" type="button" class="btn btn-outline" (click)="openAppointmentDetail()">
            Besigtig afspraak
        </button>
        <button type="button" *ngIf="appointmentAction === sidenavActions.ReviewAppointment" mat-stroked-button (click)="rejectAppointmentRequest()">
            Keur af
        </button>

        <div class="spacer"></div>
        <button *ngIf="appointmentAction !== sidenavActions.ReviewAppointment" mat-raised-button color="accent" [disabled]="appointmentForm.invalid || loading || recordingNotes" type="submit" class="btn btn-outline-secondary">
            {{ loading ? '...' : 'Stoor' }}
        </button>
        <button *ngIf="appointmentAction === sidenavActions.ReviewAppointment" mat-raised-button color="accent" [disabled]="appointmentForm.invalid || loading || recordingNotes" type="button" class="btn btn-outline-secondary" (click)="acceptAppointmentRequest()">
            Aanvaar
        </button>
    </div>
</form>