<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>

<div *ngIf="parent?.DriveId">
        <h3 *ngIf="files?.length < 1" class="m-3 text-muted">No files found</h3>
        <div class="d-flex justify-content-center" *ngIf="files?.length > 0">
            <div *ngIf="files && files.length > 0" class="flex-1 align-items-center scrollable border-primary p-2 mr-2">
            <div *ngFor="let file of files" class="mb-3 link bg-primary p-2 fileList border-radius-5 text-white text-center" (click)="getFile(file.id, file.mimeType, file.name)">
                        <fa-icon class="text-white mr-3" [icon]="faFile"></fa-icon> {{file.name}}
              </div>
            </div>
            
<!-- 
              <div *ngIf="files?.length > 0" class="relative flex-1" >
    
                <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
                    <div>
                        <mat-spinner color="accent"></mat-spinner>
                    </div>
                </div>
                <div class="showcase scrollable">
                <iframe id="showcaseFile" width="100%" height="100%"></iframe>
            </div> -->
    
        </div>

  </div>
<button mat-stroked-button color="accent" (click)="createDriveFolder()" *ngIf="!parent.DriveId">Create Drive</button>
    <div class="m-3" *ngIf="parent?.DriveId" mat-dialog-actions>
        <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileInput

      /><button [disabled]="!uploadedFile?.source" mat-stroked-button color="primary" (click)="uploadFile()">upload</button>
    </div>



