import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReadMoreDialogComponent } from '../read-more-dialog/read-more-dialog.component';

@Component({
  selector: 'app-bedieninge',
  templateUrl: './bedieninge.component.html',
  styleUrls: ['./bedieninge.component.scss']
})
export class BedieningeComponent implements OnInit {
  groups = [
    {
      title: 'Groei Groepe',
      description: 'Info about the group',
      url: '',
      icon: '../../../assets/icon_groeigroepe.png'
    },
    {
      title: 'Belangegroepe',
      description: 'Info about the group',
      url: '',
      icon: '../../../assets/icon_belangegroepe.png'
    },
    {
      title: 'Lewens groei',
      description: 'Info about the group',
      url: '',
      icon: '../../../assets/icon_lewensgroei.png'
    },
    {
      title: 'Bedieninge',
      description: 'Info about the group',
      url: '',
      icon: '../../../assets/icon_bedien.png'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  readonly dialog = inject(MatDialog);

  openDialog(group: any) {
    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: group
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
