import { Injectable } from '@angular/core';
import { DailyReport, MedicalDetails, Student, StudentConsent, StudentSearchCriteria } from '../../../../backend/interfaces/student.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MealPlan } from 'backend/interfaces/meal-plan.interface';

@Injectable({
  providedIn: 'root'
})

export class StudentService {
  students = new BehaviorSubject<Student[] | null>(null);
  students$ = this.students.asObservable();
  studentBirthdays = new BehaviorSubject<any[] | null>(null);
  studentBirthdays$ = this.studentBirthdays.asObservable();
  studentSearch = new BehaviorSubject<{name:string, surname: string} | null>(null);
  studentsSearch$ = this.studentSearch.asObservable();
  activatedStudent = new BehaviorSubject<Student>(null);
  activatedStudent$ = this.activatedStudent.asObservable();
  activatedPhotoAlbum = new BehaviorSubject<any[]>(null);
  activatedPhotoAlbum$ = this.activatedPhotoAlbum.asObservable();
  activatedDrive = new BehaviorSubject<any[]>(null);
  activatedDrive$ = this.activatedDrive.asObservable();
  studentCount = new BehaviorSubject<number | null>(null);
  studentCount$ = this.studentCount.asObservable();
  uploadToken: string;

  public newStudentEmail: string;
  constructor(private http: HttpClient) {
  }

  saveStudent(student: Student): Promise<Student> {
    return Parse.Cloud.run('saveStudent', { student }).then((result) => {
      return result;
    });
  }

  saveStudents(students: Student[]): Promise<Student> {
    return Parse.Cloud.run('saveStudents', { students }).then((result) => {
      return result;
    });
  }

  saveStudentConsent(studentConsent: StudentConsent): Promise<Student> {
    return Parse.Cloud.run('saveStudentConsent', { studentConsent }).then((result) => {
      return result;
    });
  }

  saveStudentMedicalDetails(medicalDetails: MedicalDetails): Promise<Student> {
    return Parse.Cloud.run('saveStudentMedicalDetails', { medicalDetails }).then((result) => {
      return result;
    });
  }

  getStudents(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getStudents', { displayLimit, page }).then((result) => {
      this.students.next(result);
    });
  }

  getStudentById(id: string): Promise<Student> {
    return Parse.Cloud.run('getStudentById', { id });
  }

  getStudentByEmail(email: string): Promise<Student> {
    return Parse.Cloud.run('getStudentByEmail', { email });
  }

  getCalendarBusy(): Promise<any>{
    const from = "2023-01-01T00:00:00.000Z";
    const to = "2023-04-01T00:00:00.000Z";
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getCalendarEvents(): Promise<any>{
    return Parse.Cloud.run('getCalendarEvents');
  }

  getStudentCount(): Promise<number>{
    return Parse.Cloud.run('getStudentCount').then((result) => {
      this.studentCount.next(result);
    });;
  }

  createBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('createBooking', { event });
  }

  updateBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('updateBooking', { event });
  }

  deleteBooking(id: string): Promise<any>{
    return Parse.Cloud.run('deleteBooking', { id });
  }

  searchStudent(criteria: StudentSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchStudent', { criteria });
  }

  saveProfilePicture(studentId: string, image: any): Promise<any>{
    console.log(image)
    return Parse.Cloud.run('saveStudentProfilePicture', { studentId, image });
  }

  getStudentFile(id: string, mimeType: string): Promise<any>{
    return Parse.Cloud.run('getFileById', { id, mimeType })
  }

  getstudentFiles(fileId: string): Promise<any>{
    return Parse.Cloud.run('getFilesById', { fileId })
  }

  getAlbumList(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumList', { id })
  }

  getAlbumById(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumById', { id })
  }

  createDriveFolder(studentId: string): Promise<any>{
    return Parse.Cloud.run('createStudentDriveFolder', { studentId })
  }

  createPhotoAlbum(studentId: string): Promise<any>{
    return Parse.Cloud.run('createPhotoAlbum', { studentId })
  }

  uploadFile(id: string, uploadedFile: any, description: string): Promise<any>{
    return Parse.Cloud.run('uploadFile', { id, uploadedFile, description })
  }

  async uploadPhoto(id: string, uploadedFile: any, description: string): Promise<any>{
    const authToken = await Parse.Cloud.run('getGToken', { });
    let body = uploadedFile.source;
    let newMediaItems = [];

    const reqHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/octet-stream',
        'X-Goog-Upload-Content-Type': uploadedFile.type,
        'X-Goog-Upload-File-Name': uploadedFile.name,
        'X-Goog-Upload-Protocol': 'raw'
      })
    

      const batchCreateHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/json'
      })
    
    let uploadToken = await this.http.post(
      'https://photoslibrary.googleapis.com/v1/uploads',
      body, {headers: reqHeaders, responseType: 'text'}).toPromise(); 

      // uploadToken.tap(res => {
    newMediaItems.push(
          {
            description: uploadedFile.description,
            simpleMediaItem: {
              fileName: uploadedFile.name,
              uploadToken: uploadToken
            }
          });

      return this.http.post(
            'https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate',
            { albumId: id, newMediaItems }, {headers: batchCreateHeaders}).toPromise();

    // return Parse.Cloud.run('uploadPhoto', { id, uploadedFile })

  }

  saveDailyReport(dailyReport: DailyReport): Promise<DailyReport> {
    return Parse.Cloud.run('saveStudentDailyReport', { dailyReport }).then((result) => {
      return result;
    });
  }

  getStudentDailyReport(studentID: string, fromDate: string, toDate: string, reportId?: string): Promise<DailyReport> {
    return Parse.Cloud.run('getStudentDailyReport', { studentID, fromDate, toDate, reportId });
  }

  getBirthdays(date: string): Promise<any> {
    return Parse.Cloud.run('getStudentBirthdays', { date }).then((result) => {
      this.studentBirthdays.next(result);
    });
  }
  
}

