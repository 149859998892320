import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Parse from 'parse';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  appointments = new BehaviorSubject<GoogleCalendarEvent[]>(null)
  appointments$ = this.appointments.asObservable();
  appointment = new BehaviorSubject<CalendarEvent>(null);
  appointment$ = this.appointment.asObservable();
  appointmentRequests = new BehaviorSubject<any[]>(null)
  appointmentRequests$ = this.appointments.asObservable();
  constructor() { }

  setAppointment(appointment: CalendarEvent) {
    console.log(appointment)
    this.appointment.next(appointment);
  }

  createAppointment(event: GoogleCalendarEvent): Promise<any> {
    return Parse.Cloud.run('createBooking', { event });
  }

  updateAppointment(event: any): Promise<any> {
    return Parse.Cloud.run('updateBooking', { event });
  }

  acceptAppointmentRequest(event: GoogleCalendarEvent): Promise<any> {
    return Parse.Cloud.run('acceptAppointmentRequest', { event });
  }


  deleteAppointment(id: string): Promise<any> {
    return Parse.Cloud.run('deleteBooking', { id });
  }

  rejectAppointment(appointmentRequestId: string | number): Promise<any> {
    return Parse.Cloud.run('rejectAppointmentRequest', { appointmentRequestId });
  }

  getCalendarBusy(): Promise<any> {
    const from = "2023-01-01T00:00:00.000Z";
    const to = "2023-04-01T00:00:00.000Z";
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getCalendarEvents(): Promise<any> {
    return Parse.Cloud.run('getCalendarEvents');
  }

  getAppointment(appointmentId: string): Promise<any> {
    return Parse.Cloud.run('getAppointment', { appointmentId: appointmentId });
  }

  getStudentAppointments(email: string): Promise<any> {
    return Parse.Cloud.run('getStudentAppointments', { studentEmail: email });
  }

  createAppointmentRequest(appointment: any): Promise<any> {
    return Parse.Cloud.run('createAppointmentRequest', { appointment });
  }

  getAppointmentRequests(): Promise<any> {
    return Parse.Cloud.run('getAppointmentRequests').then(res => {
      let requests = [];
      res.forEach(e => {
        requests.push({
          id: e.appointmentRequestId,
          start: moment(e.appointmentDate).utcOffset('+0200').toDate(),
          end: moment(e.endTime).utcOffset('+0200').toDate(),
          title: e.appointmentType + ' - ' + e.studentName + (e.preferredPractitioner ? ' - ' + e.preferredPractitioner.name : ' - Any'),
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          meta: { appointmentType: { summary: e.appointmentType, duration: e.appointmentDuration }, description: e.appointmentType, id: e.appointmentRequestId, studentEmail: e.studentEmail, studentName: e.studentName, appointmentLocation: e.appointmentLocation, preferredPractitioner: e.preferredPractitioner, additionalNotes: e.additionalNotes },
          draggable: true,
          cssClass: 'eventColor' + (e.colorId ? e.colorId : 1)
        })
        this.appointmentRequests.next(requests);
      });
    })
  }

  getAppointmentsByLocation(location: string): Promise<any> {
    return Parse.Cloud.run('getAppointmentsByLocation', { location }).then((res) => {
      let events = [];
      res.forEach(e => {
        events.push({
          id: e.id,
          start: moment(e.appointmentDate).utcOffset('+0200').toDate(),
          end: moment(e.endTime).utcOffset('+0200').toDate(),
          title: e.summary + ' - ' + e.studentName + (e.preferredPractitioner ? ' - ' + e.preferredPractitioner.name : ' - Any'),
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          meta: { appointmentType: { summary: e.summary, duration: e.duration }, description: e.description, id: e.id, studentEmail: e.studentEmail, studentName: e.studentName, studentSurname: e.studentSurname, appointmentLocation: e.appointmentLocation, preferredPractitioner: e.preferredPractitioner, additionalNotes: e.additionalNotes },
          draggable: true,
          cssClass: 'eventColor' + (e.colorId ? e.colorId : 1)
        })
      })
      this.appointments.next(events);
    });
  }

  getAppointmentsByEmail(email: string): Promise<any> {
    return Parse.Cloud.run('getAppointmentsByEmail', { email });
  }

  sendReminderEmails(): Promise<any> {
    return Parse.Cloud.run('sendReminderEmails');
  }

}
