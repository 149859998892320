import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { StudentService } from 'src/app/admin/services/student.service';
import { SidenavActions, SideNavService } from 'src/app/admin/services/side-nav.service';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DataService } from 'src/app/services/data.service';
import { Location } from '../../../../backend/interfaces/location.interface';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('bookingsModal', { static: true }) bookingsModal: TemplateRef<any>;
  @Output('updateParentLoader') updateParentLoader: EventEmitter<boolean> = new EventEmitter(null);
  @Output('openSideNav') openSideNav: EventEmitter<CalendarEvent> = new EventEmitter(null);
  @Input() location;
  @Input() email;
  @Input() loading;
  locations: Location[] = [];
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;

  viewDate: Date = new Date();

  refresh = new Subject<void>();

  events: CalendarEvent[] = [
  ];
  selectedLocation: string = 'All';
  event: CalendarEvent;
  newBookingForm: UntypedFormGroup;
  activeDayIsOpen: boolean = true;
  constructor(private modal: MatDialog, private appointmentService: AppointmentService, private fb: UntypedFormBuilder, public sideNavService: SideNavService, private dataService: DataService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.dataService.locations$.subscribe(res => {
      this.locations = res;
    });

    // if(this.email){
    //   this.getEventsByEmail(this.email);
    // } else {
      // this.getEventsByLocation(this.selectedLocation);
    //   }
    this.appointmentService.appointments$.subscribe((res: any[]) => {
      this.events = res;
      this.loading = false;
      this.updateParentLoader.emit(false);
    })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (moment(date).isSame(this.viewDate, 'month')) {
      if (
        (moment(this.viewDate).isSame(date, 'day') && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent(event);
  }

  handleEvent(event: CalendarEvent): void {
    if(event.meta?.appointmentType){
      this.viewAppointment(event);
    }else{
      this.createAppointment(event);
    }
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  createAppointment(e: any): void {
    this.newBookingForm = this.fb.group(
      {
        summary: '',
        description: '',
        start: e.date,
        end: moment(e.date).add(1, 'hours').toDate(),
        meta: {},
    });
    this.openSideNav.emit(this.newBookingForm.value);
  }

  viewAppointment(e: CalendarEvent): void {
    this.openSideNav.emit(e);
  }


  async setLocation(event: MatSelectChange): Promise<void> {
    this.loading = true;
    this.updateParentLoader.emit(true);
    this.selectedLocation = event.value;
    if(this.email){
      this.getEventsByEmail(this.email);
    } else {
      this.getEventsByLocation(this.selectedLocation);
      }
  }


  getEventsByLocation(location: string): void {
    this.events = [];
    const loc = (location === 'All' ? null : this.selectedLocation);
    this.appointmentService.getAppointmentsByLocation(loc).then(res => {
      res.forEach(e => {
        this.events.push({
          id: e.id,
          start: moment(e.appointmentDate).utcOffset('+0200').toDate(),
          end: moment(e.endTime).utcOffset('+0200').toDate(),
          title: e.summary +' - '+ e.studentName + (e.preferredPractitioner? ' - ' + e.preferredPractitioner.name  : ' - Any'),
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          meta: {appointmentType: {summary: e.summary, duration: e.duration},description: e.description, id: e.id, studentEmail: e.studentEmail, studentName: e.studentName, appointmentLocation: e.appointmentLocation, preferredPractitioner: e.preferredPractitioner, additionalNotes: e.additionalNotes},
          draggable: true,
          cssClass: 'eventColor'+(e.colorId ? e.colorId : 1) 
      })
      })
      this.loading = false;
      this.updateParentLoader.emit(false);
    })
  }

  getEventsByEmail(email: string): void {
    this.events = [];
    this.appointmentService.getAppointmentsByEmail(email).then(res => {
      console.log(res)
      res.forEach(e => {

        this.events.push({
          id: e.id,
          start: moment(e.appointmentDate).utcOffset('+0200').toDate(),
          end: moment(e.endTime).utcOffset('+0200').toDate(),
          title: e.summary +' - '+ e.appointmentLocation + (e.preferredPractitioner? ' - ' + e.preferredPractitioner.name + ' ' + e.preferredPractitioner.surname : ' - Any'),
          allDay: false,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
          meta: {appointmentType: e.summary,description: e.description, id: e.id, studentEmail: e.studentEmail, appointmentLocation: e.appointmentLocation, preferredPractitioner: e.preferredPractitioner, additionalNotes: e.additionalNotes},
          draggable: true,
          cssClass: 'eventColor'+(e.colorId ? e.colorId : 1) 
      })
      })
      this.loading = false;
      this.updateParentLoader.emit(false);
    })
  }

  sendReminderEmails(): void{
    this.appointmentService.sendReminderEmails();
  }
}
