  import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { MemberSearchCriteria } from 'backend/interfaces/member.interface';

import { ParentService } from 'src/app/admin/services/parent.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';

@Component({
  selector: 'app-search-parent',
  templateUrl: './search-parent.component.html',
  styleUrls: ['./search-parent.component.scss']
})
export class SearchParentComponent implements OnInit {
  loading = false;
  searchParentForm: UntypedFormGroup;
  noResults = false;
  faSearch = faSearch;
  searchPhrase: MemberSearchCriteria;
  constructor(private fb: UntypedFormBuilder, private parentService: ParentService, private sideNavService: SideNavService, private router: Router) { }

  ngOnInit(): void {
      this.searchParentForm = this.fb.group(
        {
          name: [''],
          surname: [''],
          mobile: [''],
          email: ['']
      });
      this.parentService.parentsSearch$.subscribe(r => {
        this.searchPhrase = r;
        this.searchParentForm.patchValue({...r})
      })
  }

  search(){
    this.noResults = false;
    this.loading = true;
    this.parentService.searchParent(this.searchParentForm.value).then(res => {
      if(res.parents.length > 0){
        this.parentService.parentSearch.next(this.searchParentForm.value);
        this.parentService.parents.next(res.parents);
        this.parentService.parentCount.next(res.count);
        this.router.navigate(['admin','members']);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }

  async clearSearch(){
    this.parentService.parentSearch.next({name: '', surname: '', mobile: '', email: ''});
    this.loading = true;
    await this.parentService.getParentCount();
    await this.parentService.getParents(10, 0);
    this.loading = false;
  }
}
