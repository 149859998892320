<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="saving"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<ng-container *ngIf="consentForm">

          <div class="w-80" #ConsentContainer [innerHtml]="consent | safeHtml: this.student.Name +' '+this.student.Surname"></div>
          <form class="user" [formGroup]="consentForm" (ngSubmit)="saveStudentConsent()">
          <h2 id="Consent">Aanvaar jy die bogenoemde vrywaring van Kruin Kinderkerk?</h2>
          <mat-radio-group
            class="bigRadio"
            aria-labelledby="Consent"
            formControlName="InformedConsent"
          >
            <mat-radio-button class="example-radio-button m-3" [value]="true">
              Ja
            </mat-radio-button>
            <mat-radio-button class="example-radio-button m-3" [value]="false">
              Nee
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
            *ngIf="
              consentForm.controls.InformedConsent.errors ||
              (!student?.InformedConsent &&
                !consentForm?.controls['InformedConsent'].value)
            "
            >Om U kind by kinderkerk in te skryf moet U asb die vrywaring aanvaar.</mat-error
          >

  <mat-card class="text-center" >
    <mat-card-content class="m-3">
      <div class="row">
        <div class="col-md-8 d-flex flex-column justify-content-center">
          <h2>Handtekening</h2>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-center">
          <img #signatureElement />
          <button
            mat-raised-button
            color="accent"
            type="button"
            (click)="openDialog()"
          >
            Teken
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <p *ngIf="consentForm.invalid" class="text-error">
    Vrywaring moet asb aanvaar word.
  </p>
  <mat-toolbar class="d-flex justify-content-between" *ngIf="student">
    <button type="button" mat-button (click)="back()">Terug</button>
    <!-- <div class="d-flex"> -->
      <div *ngIf="student.Document?.source">
        <button
          class="mr-3"
          mat-flat-button
          type="button"
          *ngIf="student && student?.Document?.name"
          (click)="download()"
        >
          <fa-icon [icon]="faDownload"></fa-icon>Laai vrywaring af
        </button>
      </div>
      <button *ngIf="signed" mat-button type="button" (click)="generatePDF('view')"><i class="fa fa-download fa-2x" aria-hidden="true"></i>&nbsp;Voorskou</button>
      <button mat-raised-button type="submit" color="primary" [disabled]="!parent">
        {{ saving ? "Bevestig" : "Bevestig" }}
      </button>
    <!-- </div> -->
  </mat-toolbar>
</form>
</ng-container>