import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { StudentService } from 'src/app/admin/services/student.service';
import lgZoom from 'lightgallery/plugins/zoom';

@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.scss']
})
export class PhotoAlbumComponent implements OnInit {
  profile;
  files;
  loading = false;
  selectedFile: {type: string, src: string};
  uploadedFile = {name: '', type: '', source: null};
  settings = {
    counter: false,
    plugins: [lgZoom],
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
      const { index, prevIndex } = detail;
      console.log(index, prevIndex);
  };


  constructor(private studentService: StudentService, private _snackBar: MatSnackBar) { 
  //   this.studentService.activatedStudent$.subscribe(async (s) => {
  //     if(s){
  //       this.profile = s;
  //       this.studentService.getAlbumById(this.profile?.PhotoAlbumId).then(async res => {
  //         this.studentService.activatedPhotoAlbum.next(res.mediaItems);
  //       });
  //     }
  //  });
  }

  async ngOnInit() {
    this.loading = true;
    this.studentService.activatedStudent$.subscribe(async s => {
      this.profile = s;
    });
    if(this.profile.PhotoAlbumId){
      this.studentService.activatedPhotoAlbum$.subscribe(async (a) => {
        this.files = a;
      });
    }
    this.loading = false;
  }

  createPhotoAlbum(){
    this.loading = true;
    this.studentService.createPhotoAlbum(this.profile.id).then(async result => {
      this.profile.PhotoAlbumId = await result.get('PhotoAlbumId');
      this.studentService.getAlbumById(this.profile.PhotoAlbumId).then(s => {
        this.studentService.activatedPhotoAlbum.next(s);
        this.files = s;
      })
      // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      // this.router.navigate(['admin', 'member', {id: this.student.ID}]));
      this.loading = false;
    });
  }

  uploadPhoto(){
    let description = '';
    this.loading = true;
    this.studentService.uploadPhoto(this.profile.PhotoAlbumId, this.uploadedFile, description).then(res => {
      console.log('this.files: ', this.files);
      console.log(this.uploadedFile)
      console.log(res)
      this.files.push(res.newMediaItemResults[0].mediaItem);
      this.openSnackBar(res.newMediaItemResults[0].status.message);

      this.loading = false;
    }).catch(err => err);
  }

  async onPhotoSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsArrayBuffer(file);
      console.log(file);
          reader.onload = (e) => {
              this.uploadedFile = {
                source: reader.result,
                type: file.type,
                name: file.name
              }
          };
          // this.uploadedFile.source = new Int8Array(this.uploadedFile.source as ArrayBuffer);
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
  

  // uploadPhotos(){
  //   console.log(this.uploadedImage);
  //   let description = '';
  //   this.studentService.uploadFile(this.student.DriveId, this.uploadedImage, description).then(res => {
  //     console.log(res)
  //   });
  // }
}
