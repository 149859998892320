<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<form
  *ngIf="!loading"
  class="user mb-5"
  [formGroup]="groupForm"
  (ngSubmit)="saveGroup()"
>
  <div class="mb-3 mt-3">
    <div class="row m-0 mb-3">
      <div [ngClass]="this.group.id !== '' ? 'col-md-6' : 'col-md-12'" class="d-flex align-items-center">
        <div class="border-primary p-2 mb-3 w-100 h-100">

            <div class="photo-container border-radius-5 link" (click)="uploadFile()">
              <fa-icon
                class="upload-icon text-secondary"
                [icon]="faUpload"
                size="2x"
              ></fa-icon>
              <img
                class="text-center margin-auto link"
                [src]="logo"
                #imagePreview
                alt="image"
              />
              <input
                type="file"
                (change)="onFileSelected($event)"
                #fileInput
                formControlName="logo"
                style="display: none"
              />
            </div>

              <mat-form-field class="w-100 mb-3">
                <mat-label>Naam</mat-label>
                <input class="m-0" matInput formControlName="name" placeholder="Naam" />
              </mat-form-field>
              <!-- <mat-form-field appearance="fill" class="w-100">
                <mat-label>Meal Plan</mat-label>
                <mat-select formControlName="mealPlan" name="mealPlan">
                  <mat-option
                    *ngFor="let mealPlan of mealPlans"
                    [value]="mealPlan"
                    >{{ mealPlan.title }}</mat-option
                  >
                </mat-select>
              </mat-form-field> -->
              <ng-container formArrayName="subGroups">
                <h3>Klasse</h3>
                <ng-container *ngFor="let subGroupForm of subGroup?.controls; let i = index">
                  <div class="flex-wrap medication-form-row d-flex justify-content-between align-items-center" [formGroup]="subGroupForm">
                    <mat-form-field  class="flex-wrap flex-1 ml-3 input-sm">
                      <mat-label>Naam</mat-label>
                      <input matInput placeholder="Klas naam" name="name" formControlName="name" />
          
                    </mat-form-field>
                    <mat-form-field  class="flex-wrap flex-1 ml-3">
                      <!-- <mat-label>Qty</mat-label> -->
                      <input matInput name="description" placeholder="Beskrywing" formControlName="description" />
                    </mat-form-field>
                    <mat-form-field  class="flex-wrap flex-1 ml-3 input-sm">
                      <mat-label>Kode</mat-label>
                      <input matInput placeholder="Klas kode" name="groupCode" formControlName="groupCode" />
          
                    </mat-form-field>
                      <fa-icon class="ml-3 mr-3 link" [icon]="faTrash" (click)="removeSubGroup(i)"></fa-icon> 
                  </div>
              </ng-container>
            </ng-container>
              <button mat-mini-fab type="button" (click)="addSubGroup()">
                <fa-icon [icon]="faPlus"></fa-icon> 
              </button>
              <h3>Notas</h3>
              <mat-form-field class="w-100" appearance="fill">
                <textarea
                  rows="5"
                  matInput
                  placeholder="Notas"
                  formControlName="notes"
                ></textarea>
              </mat-form-field>
              
        </div>
      </div>
      <div *ngIf="this.group.id !== ''" class="col-md-6 d-flex flex-column">
        <h3>Kinders</h3>
        <div class="d-flex flex-wrap">
          <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let s of students" (click)="openStudent(s.ID)"> <fa-icon [icon]="faUser"></fa-icon>  {{s.Name}} {{s.Surname}}</button>
        </div>
        <hr/>
        <h3>Personeel</h3>
        <div class="d-flex flex-wrap">
          <button class="mr-2 mb-2" type="button" mat-stroked-button color="accent" *ngFor="let t of teachers" (click)="openTeacher(t.id)"> <fa-icon [icon]="faUser"></fa-icon>  {{t.title}} {{t.name}} {{t.surname}}</button>
        </div>
      </div>

    </div>
  </div>
  <mat-toolbar class="d-flex justify-content-between">
    <button
      *ngIf="group.id"
      type="button"
      (click)="openDeleteDialog()"
      mat-stroked-button
      color="accent"
    >
      Verwyder
    </button>
    <button type="submit" mat-raised-button color="primary">Skep</button>
  </mat-toolbar>
</form>
