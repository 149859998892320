<div mat-dialog-title class="d-flex">
    <h1>Appointments</h1>
    </div>
    
    <div mat-dialog-content class="text-center">
        <div *ngFor="let appointment of data.appointments" class="d-flex flex-1 justify-content-start border-primary p-3 m-2 align-items-center">
            <div class="flex-1">
               <p>{{appointment.startTime ? (appointment.startTime | date:'medium') : (appointment.appointmentDate | date:'dd MMM yy, HH:mm')}} - {{appointment.summary}}
                </p>
                <p>{{appointment.preferredPractitioner? ' - ' + appointment.preferredPractitioner.name + ' ' + appointment.preferredPractitioner.surname : ' - Any'}}</p>
                <p *ngIf="appointment.accepted === false">Rejected</p>
                <p *ngIf="appointment.accepted">Accepted</p>
                <p *ngIf="appointment.accepted === undefined">Pending review</p>
            </div>
          </div>
    </div>
    <div mat-dialog-actions class="d-flex">
        <button mat-button (click)="close()">Close</button>
    </div>