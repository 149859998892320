
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

  <mat-card class="m-3">
    <mat-card-content>
        <form class="user" [formGroup]="serviceForm" (ngSubmit)="saveService()">

        
          <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                  <mat-label>Titel</mat-label>
                  <input matInput placeholder="title" formControlName="title">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Beskrywing</mat-label>
                  <input matInput placeholder="description" formControlName="description">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Tydsduur</mat-label>
                  <input matInput placeholder="duration" formControlName="duration">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Prys/Koste</mat-label>
                <input matInput placeholder="price" formControlName="price">
            </mat-form-field>
          </div>

        <mat-toolbar class="d-flex justify-content-between">
            <button mat-flat-button color="error" aria-label="Delete" *ngIf="service" type="button" (click)="deleteService()">
              <fa-icon [icon]="faTrash"></fa-icon> 
            </button>
            <button type="submit" [disabled]="!serviceForm.valid || saving" mat-raised-button color="primary">
                Stoor
            </button>
          </mat-toolbar>
        </form>
    </mat-card-content>
  </mat-card>