import { Injectable } from '@angular/core';
import { Ministry, MinistrySearchCriteria } from 'backend/interfaces/ministry.interface';
import { Member } from 'backend/interfaces/member.interface';


import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinistryService {

  ministries = new BehaviorSubject<Ministry[] | null>(null);
  ministries$ = this.ministries.asObservable();
  ministryCount = new BehaviorSubject<number | null>(null);
  ministryCount$ = this.ministryCount.asObservable();
  openMinistry: Ministry;
  constructor() {}

  saveMinistry(ministry: Ministry): Promise<Ministry> {
    return Parse.Cloud.run('saveMinistry', { ministry }).then((result) => {
      return result;
    });
  }

  getMinistries(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getMinistries', { displayLimit, page }).then((result) => {
      this.ministries.next(result);
    });
  }

  getMinistryById(id: string): Promise<Ministry> {
    return Parse.Cloud.run('getMinistryById', { id });
  }

  getMembersByMinistry(ministry: string): Promise<Member[]> {
    return Parse.Cloud.run('getMembersByMinistry', { ministry });
  }

  getMinistryCount(): Promise<number>{
    return Parse.Cloud.run('getMinistryCount').then((result) => {
      this.ministryCount.next(result);
    });;
  }

  deleteMinistry(id: string): Promise<any>{
    return Parse.Cloud.run('deleteMinistry', { id });
  }

  searchMinistry(criteria: MinistrySearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchMinistry', { criteria });
  }

}