<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
                <div class="p-5 bg-50perc-white">
                    <img class="mobile img-fluid relative" src="./assets/icon.png" />
                    <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-2">Skep jou profiel</h1>
                    </div>
                    <div class="text-center mb-4">
                        <button class="btn-block" mat-button routerLink="/login">Alreeds gedoen? Teken in!</button>
                    </div>
                    <form class="user" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <input type="text" formControlName="id" class="form-control form-control-user"
                                placeholder="ID Number">
                        </div>
                        <div class="form-group">
                            <input type="text" formControlName="name" class="form-control form-control-user"
                                placeholder="Name">
                        </div>
                        <div class="form-group">
                            <input type="text" formControlName="surname" class="form-control form-control-user"
                                placeholder="Surname">
                        </div>
                        <div class="form-group">
                            <input type="text" formControlName="mobile" class="form-control form-control-user"
                                placeholder="Mobile Number">
                        </div>
                        <div class="form-group">
                            <input type="email" formControlName="email" class="form-control form-control-user"
                                placeholder="Email Address">
                        </div>
                        <div class="form-group input-group">
                            <input name="password" type="password" placeholder="Password" id="password" name="password" [type]="passwordVisible?'text':'password'" class="form-control form-control-user"
                            formControlName="password" >
                            <div class="input-group-append">
                            <span class="input-group-text password-visibility" id="basic-addon2" (mousedown)="togglePassword(true)" (mouseup)="togglePassword(false)">
                                <fa-icon class="eye-icon" *ngIf="!passwordVisible" [icon]="faEye"></fa-icon> 
                                <fa-icon class="eye-icon" *ngIf="passwordVisible" [icon]="faEyeSlash"></fa-icon> 
                            </span>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-secondary btn-user btn-block">
                            Registreer
                        </button>
                    </form>
                    <hr>
                    <!-- <div class="text-center">
                        <button mat-button routerLink="/forgot-password">Forgot Password?</button>
                    </div> -->
                </div>
