
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

  <mat-card class="m-3">
    <mat-card-header>
      <mat-card-title class="mt-3">Lokaal</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form class="user" [formGroup]="locationForm" (ngSubmit)="saveLocation()">
        <div class="row d-flex p-3">
        
          <div class="col-md-6 d-flex flex-column">
              <mat-form-field appearance="outline">
                  <mat-label>Naam</mat-label>
                  <input matInput placeholder="name" formControlName="name">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Adres</mat-label>
                  <input matInput placeholder="address" formControlName="address">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Koordinate</mat-label>
                  <input matInput placeholder="coordinates" formControlName="coordinates">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Kleur (0-9)</mat-label>
                <input matInput type="number" placeholder="color id" formControlName="colorId">
            </mat-form-field>
          </div>
        </div>
        <mat-toolbar class="d-flex justify-content-between">
          <button mat-flat-button color="error" aria-label="Delete" *ngIf="location" type="button" (click)="deleteLocation()">
            <fa-icon [icon]="faTrash"></fa-icon> 
          </button>
            <button type="submit" [disabled]="!locationForm.valid || saving" mat-raised-button color="primary">
                {{ locationForm.dirty ? 'Stoor' : 'Maak toe' }}
            </button>
          </mat-toolbar>
        </form>
    </mat-card-content>
  </mat-card>